import { useEffect, useState } from "react"
import {
    Button,
    Feed,
    FeedContent,
    FeedDate,
    FeedEvent,
    FeedExtra,
    FeedLabel,
    FeedSummary,
    Header,
    List,
    ListItem,
    Popup,
} from "semantic-ui-react"
import { API_BASE } from "../config"
import { UspNotify, UspNotifyType } from "../store/types/notifications"

export interface UspNotifyItem {
    datetime: Date
    notification: UspNotify
}

const getNotificationIcon = (item: UspNotifyItem) => {
    switch (item.notification.type) {
        case UspNotifyType.ValueChange:
            return "sync alternate"
        case UspNotifyType.Event:
            return "exclamation triangle"
        case UspNotifyType.ObjectCreation:
            return "plus square"
        case UspNotifyType.ObjectDeletion:
            return "minus square"
        case UspNotifyType.OperationComplete:
            return item.notification.error === null ? "check circle" : "times circle"
    }
}

const createNotificationElement = (item: UspNotifyItem) => {
    const notif = item.notification
    switch (notif.type) {
        case UspNotifyType.ValueChange:
            return (
                <FeedSummary>
                    Parameter <a>{notif.path}</a> changed to <a>{notif.value}</a>
                </FeedSummary>
            )
        case UspNotifyType.Event:
            return (
                <>
                    <FeedSummary>
                        Event <a>{notif.name}</a> occurred on path <a>{notif.path}</a> with parameters:
                    </FeedSummary>
                    <FeedExtra text>
                        <List as="ul">
                            {Array.from(Object.entries(notif.params), ([param, value]) => {
                                return (
                                    <ListItem as="li">
                                        <b>{param}</b> : {value}
                                    </ListItem>
                                )
                            })}
                        </List>
                    </FeedExtra>
                </>
            )
        case UspNotifyType.ObjectCreation:
            return (
                <>
                    <FeedSummary>
                        An object is created on path <a>{notif.path}</a> with unique keys:
                    </FeedSummary>
                    <FeedExtra text>
                        <List as="ul">
                            {Array.from(Object.entries(notif.uniqueKeys), ([key, value]) => {
                                return (
                                    <ListItem as="li">
                                        <b>{key}</b> : {value}
                                    </ListItem>
                                )
                            })}
                        </List>
                    </FeedExtra>
                </>
            )
        case UspNotifyType.ObjectDeletion:
            return (
                <FeedSummary>
                    The object is deleted on path <a>{notif.path}</a>
                </FeedSummary>
            )
        case UspNotifyType.OperationComplete:
            return (
                <>
                    <FeedSummary>
                        <a>{notif.commandName}</a> operation on path <a>{notif.path}</a>{" "}
                        {notif.outputArgs !== null ? "is completed with success" : "returned an error"}:
                    </FeedSummary>
                    <FeedExtra text>
                        {notif.outputArgs && (
                            <List as="ul">
                                {Array.from(Object.entries(notif.outputArgs), ([key, value]) => {
                                    return (
                                        <ListItem as="li">
                                            <b>{key}</b> : {value}
                                        </ListItem>
                                    )
                                })}
                            </List>
                        )}
                        {notif.error && (
                            <p>
                                <i>(Code: {notif.error.code})</i> {notif.error.message}
                            </p>
                        )}
                    </FeedExtra>
                </>
            )
    }
}

export const Notifications = ({ serialNumber }: { serialNumber: string }) => {
    const [notifications, setNotifications] = useState<UspNotifyItem[]>([])

    useEffect(() => {
        const notificationsSource = new EventSource(`${API_BASE}/acs/cpes/${serialNumber}/notifications`)
        notificationsSource.onmessage = (e) => {
            setNotifications((notifications) => [
                {
                    datetime: new Date(),
                    notification: JSON.parse(e.data) as UspNotify,
                },
                ...notifications,
            ])
        }
        return () => {
            notificationsSource.close()
        }
    }, [])

    return (
        <Feed size="small">
            <div style={{ display: "flex" }}>
                <Header>Notifications</Header>
                <Popup
                    content="Delete This Instance"
                    position="bottom center"
                    trigger={
                        <Button
                            circular
                            icon="trash"
                            style={{ marginLeft: "auto" }}
                            onClick={() => setNotifications((notifications) => [])}
                        />
                    }
                >
                    Clear Notifications
                </Popup>
            </div>
            {notifications.length === 0 ? (
                <>No notifications</>
            ) : (
                notifications.map((notificationItem) => (
                    <FeedEvent>
                        <FeedLabel icon={getNotificationIcon(notificationItem)} />
                        <FeedContent>
                            <FeedDate>{notificationItem.datetime.toLocaleTimeString()}</FeedDate>
                            {createNotificationElement(notificationItem)}
                        </FeedContent>
                    </FeedEvent>
                ))
            )}
        </Feed>
    )
}
