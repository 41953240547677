import { useState } from "react"
import { useSelector } from "react-redux"
import { Button, Header, Icon, List, Modal, Table } from "semantic-ui-react"
import {
    EnqueuedCommand,
    ErrorCommandResult,
    GetCommandResult,
    ResultType,
    SetCommandResult,
    flattenGetResults,
    selectCommands,
} from "../store/slices/commandSlice"

const renderCommandData = (command: EnqueuedCommand) => {
    switch (command.response?.type) {
        case ResultType.Error: {
            const errorResult = command.response.data as ErrorCommandResult

            return (
                <div>
                    Error: ({errorResult.code}) {errorResult.message}
                </div>
            )
        }
        case ResultType.Get: {
            const getResult = command.response.data as GetCommandResult
            return (
                <List>
                    {Array.from(Object.entries(flattenGetResults(getResult)), ([path, params]) => {
                        return Array.from(Object.entries(params), ([param, value]) => (
                            <List.Item>
                                <b>
                                    {path}
                                    {param}
                                </b>
                                :{value}
                            </List.Item>
                        ))
                    }).flat()}
                </List>
            )
        }
        case ResultType.Set: {
            const setResult = command.response.data as SetCommandResult

            return (
                <>
                    {Object.keys(setResult.success).length > 0 && (
                        <>
                            <Header>Success</Header>
                            <List>
                                {Array.from(Object.entries(setResult.success), ([path, params]) => {
                                    return Array.from(Object.entries(params), ([param, value]) => (
                                        <List.Item>
                                            <b color="green">
                                                {path}
                                                {param}
                                            </b>{" "}
                                            : {value}
                                        </List.Item>
                                    ))
                                }).flat()}
                            </List>
                        </>
                    )}
                    {Object.keys(setResult.errors).length > 0 && (
                        <>
                            <Header>Failed</Header>
                            <List>
                                {Array.from(Object.entries(setResult.errors), ([path, params]) => {
                                    return Array.from(Object.entries(params), ([param, value]) => (
                                        <List.Item>
                                            <b color="red">
                                                {path}
                                                {param}
                                            </b>
                                            :{value}
                                        </List.Item>
                                    ))
                                }).flat()}
                            </List>
                        </>
                    )}
                </>
            )
        }
    }
    return <></>
}

const mapStatusToIcon = (status: string) => {
    switch (status) {
        case "enqueued":
            return <Icon name="sync" />
        case "done":
            return <Icon name="check" />
        case "error":
            return <Icon name="times" />
    }
}

export const CommandsListView = () => {
    const commands = useSelector(selectCommands)
    const [activeResult, setActiveResult] = useState<EnqueuedCommand | null>(null)

    const sorted = Object.values(commands).sort((c1, c2) => (c1.createdAt > c2.createdAt ? -1 : 1))

    return (
        <>
            <Modal onClose={() => setActiveResult(null)} open={activeResult != null}>
                <Modal.Header>
                    {activeResult?.request.type} Result - ID: {activeResult?.id}
                </Modal.Header>
                <Modal.Content>{activeResult && renderCommandData(activeResult)}</Modal.Content>
            </Modal>
            <Table>
                <Table.Header>
                    <Table.HeaderCell>Command</Table.HeaderCell>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Created At</Table.HeaderCell>
                    <Table.HeaderCell>Updated At</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Header>
                {Array.from(sorted, (command) => (
                    <Table.Row>
                        <Table.Cell>{command.request.type}</Table.Cell>
                        <Table.Cell>{command.id}</Table.Cell>
                        <Table.Cell>{mapStatusToIcon(command.status)}</Table.Cell>
                        <Table.Cell>{command.createdAt}</Table.Cell>
                        <Table.Cell>{command.updatedAt}</Table.Cell>
                        <Table.Cell>
                            <Button onClick={() => setActiveResult(command)}>View</Button>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table>
        </>
    )
}
