import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export interface GlobalSetting {
    path: string
    value: string
    type: string
}

export interface GlobalSettingUpdate {
    path: string
    value: string
}

export interface SettingsState {
    globalSettings: Record<string, GlobalSetting>
    editedGlobalSettings: Record<string, string>
    loadingGlobalSettings: boolean
}

const createInitialState = (): SettingsState => {
    return {
        globalSettings: {},
        editedGlobalSettings: {},
        loadingGlobalSettings: false,
    }
}

export const listGlobalSettings = createAsyncThunk("listGlobalSettings", async (payload: {}, { rejectWithValue }) => {
    let rsp = await tryFetch("/admin/global-settings", {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as { items: GlobalSetting[] }
})

export interface UpdateGlobalSettingsPayload {
    updates: GlobalSettingUpdate[]
}

export const updateGlobalSettings = createAsyncThunk(
    "updateGlobalSettings",
    async (payload: UpdateGlobalSettingsPayload, { rejectWithValue }) => {
        let rsp = await tryFetch("/admin/global-settings", {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
        if (rsp.status >= 400) {
            return rejectWithValue("Could not update global settings")
        }
    },
)

export const settingsSlice = createSlice({
    name: "settings",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listGlobalSettings.pending, (state, _) => {
            state.loadingGlobalSettings = true
        })
        builder.addCase(listGlobalSettings.fulfilled, (state, action) => {
            let globalSettings: Record<string, GlobalSetting> = {}
            for (const setting of action.payload.items) {
                globalSettings[setting.path] = setting
            }
            state.globalSettings = globalSettings
            state.loadingGlobalSettings = false
        })
        builder.addCase(listGlobalSettings.rejected, (state, action) => {
            state.loadingGlobalSettings = false
        })

        builder.addCase(updateGlobalSettings.pending, (state, _) => {
            state.loadingGlobalSettings = true
        })
        builder.addCase(updateGlobalSettings.fulfilled, (state, action) => {
            for (const update of action.meta.arg.updates) {
                state.globalSettings[update.path] = {
                    ...state.globalSettings[update.path],
                    value: update.value,
                }
            }
            state.loadingGlobalSettings = false
        })
        builder.addCase(updateGlobalSettings.rejected, (state, action) => {
            state.loadingGlobalSettings = false
        })
    },
})

export const settingsReducer = settingsSlice.reducer

export const selectSettings = (state: RootState) => state.settings
