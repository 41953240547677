import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"
import { WifiBand } from "./radioSlice"

export type BSSRole = "Main" | "Guest" | "Backhaul"

export interface BSS {
    id: string
    name: string
    enabled: boolean
    bssid: string
    ssid: string
    isolated: boolean
    securityMode: string
    band: WifiBand | null
    role: BSSRole | null
}

interface BSSConfig {
    bss: Record<string, BSS>
    bssLoading: boolean
    lastError: string | null
}

function createInitialState(): BSSConfig {
    return {
        bss: {},
        bssLoading: false,
        lastError: null,
    }
}

interface ListBSSPayload {
    serialNumber: string
}

export const listBSS = createAsyncThunk("listBSS", async (payload: ListBSSPayload, { rejectWithValue }) => {
    let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/wifi/basic-service-sets`, {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as { items: BSS[] }
})

export interface BSSUpdateParameters {
    id: string
    ssid?: string
    enabled?: boolean
    isolated?: boolean
    securityMode?: string
    passphrase?: string
}

export interface BSSUpdates {
    updates: BSSUpdateParameters[]
}

export interface BulkUpdateBSSPayload {
    serialNumber: string
    data: BSSUpdates
}

export interface BulkUpdateBSSResponse {
    updated: BSS[]
}

export const bulkUpdateBSS = createAsyncThunk(
    "bulkUpdateBSS",
    async (payload: BulkUpdateBSSPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/wifi/basic-service-sets`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.data),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as BulkUpdateBSSResponse
    },
)

export const bssSlice = createSlice({
    name: "bss",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listBSS.pending, (state, _) => {
            state.bssLoading = true
        })
        builder.addCase(listBSS.fulfilled, (state, action) => {
            let bssMap: Record<string, BSS> = {}
            for (const bss of action.payload.items) {
                bssMap[bss.id] = bss
            }
            state.bss = bssMap
            state.bssLoading = false
            state.lastError = null
        })
        builder.addCase(listBSS.rejected, (state, action) => {
            state.bssLoading = false
            state.lastError = "Failed to get BSS Information"
        })

        builder.addCase(bulkUpdateBSS.pending, (state, _) => {
            state.bssLoading = true
        })
        builder.addCase(bulkUpdateBSS.fulfilled, (state, action) => {
            for (const bss of action.payload.updated) {
                state.bss[bss.id] = bss
            }
            state.bssLoading = false
            state.lastError = null
        })
        builder.addCase(bulkUpdateBSS.rejected, (state, action) => {
            state.bssLoading = false
            state.lastError = "Failed to get BSS Information"
        })
    },
})

export const bssReducer = bssSlice.reducer

export const selectBSS = (state: RootState) => state.bss
