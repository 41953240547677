import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    Checkbox,
    Dimmer,
    Divider,
    Dropdown,
    Header,
    Icon,
    Input,
    List,
    Loader,
    Popup,
} from "semantic-ui-react"
import { Host, fetchHosts, selectHosts } from "../store/slices/hostsSlice"
import {
    ParentalControlProfile,
    createParentalControlProfile,
    deleteParentalControlProfile,
    fetchParentalControlProfiles,
    selectParentalControlConfig,
    updateParentalControlConfig,
    updateParentalControlProfile,
} from "../store/slices/parentalControlSlice"
import { AppDispatch } from "../store/store"
import ScheduleView, { ScheduleEditView } from "./ScheduleView"

const getHostRepr = (hosts: Host[], mac: string) => {
    let repr = `${mac}`
    let name = hosts.find((host) => host.macAddress === mac)?.name
    if (name) {
        repr = `${repr} (${name})`
    }
    return repr
}

interface ParentalControlProfileCardProps {
    serialNumber: string
    profile: ParentalControlProfile
    hosts: Host[]
    isLoading: boolean
}

const ParentalControlProfileCard = ({ serialNumber, profile, hosts, isLoading }: ParentalControlProfileCardProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const [editData, setEditData] = useState<ParentalControlProfile | null>(null)
    const [nameEditData, setNameEditData] = useState<string>("")

    return (
        <Card color={profile.enabled ? "red" : "grey"}>
            <Dimmer inverted active={isLoading}>
                <Loader active={isLoading}>Loading</Loader>
            </Dimmer>
            <CardContent>
                <CardHeader>
                    {editData === null ? (
                        <>
                            {profile.name}
                            <Checkbox
                                style={{
                                    float: "right",
                                    marginRight: "0em",
                                    marginLeft: "1em",
                                }}
                                checked={profile.enabled}
                                onChange={(_event, _data) => {
                                    dispatch(
                                        updateParentalControlProfile({
                                            serialNumber: serialNumber,
                                            profileId: profile.id,
                                            updates: {
                                                enabled: !profile.enabled,
                                            },
                                        }),
                                    )
                                }}
                                toggle
                            />
                        </>
                    ) : (
                        <>
                            {`${profile.name} `}
                            <Popup trigger={<Icon name="edit" />} on="click">
                                <Input
                                    size="mini"
                                    action={
                                        <Button
                                            content="Set"
                                            onClick={(_event, _data) => {
                                                dispatch(
                                                    updateParentalControlProfile({
                                                        serialNumber: serialNumber,
                                                        profileId: profile.id,
                                                        updates: {
                                                            name: nameEditData,
                                                        },
                                                    }),
                                                )
                                                setNameEditData("")
                                            }}
                                        />
                                    }
                                    placeholder={profile.name}
                                    onChange={(_event, data) => {
                                        setNameEditData(data.value)
                                    }}
                                />
                            </Popup>
                        </>
                    )}
                </CardHeader>
                <CardDescription>
                    <p>{profile.enabled ? "Active" : "Inactive"}</p>
                    <p>
                        <b>MAC: </b>
                        {editData === null ? (
                            profile.macAddresses.map((mac) => getHostRepr(hosts, mac)).join(", ")
                        ) : (
                            <>
                                <Dropdown
                                    compact
                                    search
                                    text={
                                        editData.macAddresses.length === 0
                                            ? "Select Client..."
                                            : editData.macAddresses[0]
                                    }
                                    //defaultValue={profile.macAddresses[0]}
                                    options={hosts.map((host) => {
                                        return {
                                            key: host.macAddress,
                                            value: host.macAddress,
                                            text: getHostRepr(hosts, host.macAddress),
                                        }
                                    })}
                                    onChange={(_, data) => {
                                        setEditData((editData) => {
                                            if (editData === null) return null

                                            return {
                                                ...editData,
                                                macAddresses: [data.value as string],
                                            }
                                        })
                                    }}
                                />
                                <Button
                                    style={{
                                        marginRight: "0em",
                                        marginLeft: "1em",
                                    }}
                                    circular
                                    size="mini"
                                    onClick={(e, d) => {
                                        dispatch(
                                            updateParentalControlProfile({
                                                serialNumber: serialNumber,
                                                profileId: profile.id,
                                                updates: {
                                                    macAddresses: editData.macAddresses,
                                                },
                                            }),
                                        )
                                    }}
                                >
                                    Update
                                </Button>
                            </>
                        )}
                    </p>
                    <Divider />
                    <List>
                        {profile.schedules.map((schedule) => (
                            <ScheduleView
                                serialNumber={serialNumber}
                                profileId={profile.id}
                                schedule={schedule}
                                editMode={editData !== null}
                            />
                        ))}
                    </List>
                    {editData === null || (
                        <ScheduleEditView
                            serialNumber={serialNumber}
                            profileId={profile.id}
                            schedule={null}
                            trigger={
                                <a>
                                    <Icon circular name="plus" />
                                    Add Schedule
                                </a>
                            }
                        />
                    )}
                </CardDescription>
            </CardContent>
            <CardContent extra>
                {editData !== null ? (
                    <>
                        <Button size="small" circular onClick={() => setEditData(null)}>
                            <Icon name="undo" />
                            Exit
                        </Button>
                    </>
                ) : (
                    <>
                        <Button size="small" circular onClick={() => setEditData((_editData) => ({ ...profile }))}>
                            <Icon name="edit" />
                            Edit
                        </Button>
                        <Button
                            size="small"
                            circular
                            onClick={() =>
                                dispatch(
                                    deleteParentalControlProfile({
                                        serialNumber: serialNumber,
                                        profileId: profile.id,
                                    }),
                                )
                            }
                        >
                            <Icon name="delete" />
                            Delete
                        </Button>
                    </>
                )}
            </CardContent>
        </Card>
    )
}

export const ParentalControlView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const parentalControlConfig = useSelector(selectParentalControlConfig)
    const hosts = useSelector(selectHosts)

    useEffect(() => {
        dispatch(fetchParentalControlProfiles({ serialNumber: serialNumber }))
        dispatch(fetchHosts({ serialNumber: serialNumber }))
    }, [])

    return (
        <>
            <Header>Parental Control Profiles </Header>
            <Header>
                <Checkbox
                    label={parentalControlConfig.enabled ? "Enabled" : "Disabled"}
                    checked={parentalControlConfig.enabled}
                    onChange={(ev, data) =>
                        dispatch(
                            updateParentalControlConfig({
                                serialNumber: serialNumber,
                                updates: {
                                    enabled: data.checked,
                                },
                            }),
                        )
                    }
                    toggle
                />
            </Header>

            <Card.Group>
                {parentalControlConfig &&
                    parentalControlConfig.profiles.map((profile) => {
                        return (
                            <ParentalControlProfileCard
                                serialNumber={serialNumber}
                                profile={profile}
                                hosts={hosts}
                                isLoading={parentalControlConfig.loadingProfiles[profile.id]}
                            />
                        )
                    })}
                <Card>
                    <Dimmer inverted active={parentalControlConfig.creatingNewProfile}>
                        <Loader active={parentalControlConfig.creatingNewProfile}>Creating</Loader>
                    </Dimmer>
                    <CardContent>
                        <Header icon>
                            <Icon name="user" />
                            Create New Profile
                        </Header>
                    </CardContent>
                    <CardContent extra>
                        <Button
                            size="small"
                            circular
                            onClick={() => {
                                if (!parentalControlConfig.creatingNewProfile) {
                                    dispatch(
                                        createParentalControlProfile({
                                            serialNumber: serialNumber,
                                            parameters: {
                                                enabled: false,
                                                macAddresses: [],
                                                name: "New Profile",
                                                schedules: [],
                                            },
                                        }),
                                    )
                                }
                            }}
                        >
                            <Icon name="add" />
                            Create
                        </Button>
                    </CardContent>
                </Card>
            </Card.Group>
        </>
    )
}
