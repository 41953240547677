import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export type WifiBand = "2.4" | "5" | "6"

export interface WifiRadio {
    id: string
    enabled: boolean
    band: WifiBand
    channel: number
    bandwidth: string
    configuredBandwidth: string
    autoChannelEnabled: boolean
    possibleChannels: number[]
    supportedBandwidths: string[]
}

interface RadioConfig {
    radios: Record<string, WifiRadio>
    radioLoading: boolean
    lastError: string | null
}

function createInitialState(): RadioConfig {
    return {
        radios: {},
        radioLoading: false,
        lastError: null,
    }
}

interface ListRadioPayload {
    serialNumber: string
}

export const listRadios = createAsyncThunk("listRadios", async (payload: ListRadioPayload, { rejectWithValue }) => {
    let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/wifi/radios`, {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as { items: WifiRadio[] }
})

export interface UpdateRadioParams {
    enabled?: boolean
    channel?: number
    autoChannelEnabled?: boolean
    configuredBandwidth?: string
}

export interface UpdateRadioPayload {
    serialNumber: string
    radioId: string
    data: UpdateRadioParams
}

export const updateRadio = createAsyncThunk("updateRadio", async (payload: UpdateRadioPayload, { rejectWithValue }) => {
    let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/wifi/radios/${payload.radioId}`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(payload.data),
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as WifiRadio
})

export const radioSlice = createSlice({
    name: "radio",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(listRadios.pending, (state, _) => {
            state.radioLoading = true
        })
        builder.addCase(listRadios.fulfilled, (state, action) => {
            let radioMap: Record<string, WifiRadio> = {}
            for (const radio of action.payload.items) {
                radioMap[radio.id] = radio
            }
            state.radios = radioMap
            state.radioLoading = false
            state.lastError = null
        })
        builder.addCase(listRadios.rejected, (state, action) => {
            state.radioLoading = false
            state.lastError = "Failed to get BSS Information"
        })

        builder.addCase(updateRadio.pending, (state, _) => {
            state.radioLoading = true
        })
        builder.addCase(updateRadio.fulfilled, (state, action) => {
            state.radios[action.payload.id] = action.payload
            state.radioLoading = false
            state.lastError = null
        })
        builder.addCase(updateRadio.rejected, (state, action) => {
            state.radioLoading = false
            state.lastError = "Failed to get Radio Information"
        })
    },
})

export const radiosReducer = radioSlice.reducer

export const selectRadios = (state: RootState) => state.radios
