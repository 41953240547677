import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Grid, Header, Icon, Loader, Menu, Popup, Segment, Tab, TabPane, Table } from "semantic-ui-react"
import { v4 as uuidv4 } from "uuid"
import {
    CommandType,
    clear,
    getPathOnDM,
    selectDataModel,
    selectHasEnqueued,
    sendCommand,
} from "../store/slices/commandSlice"
import { clearDataModelEdit, selectChangeSet } from "../store/slices/dataModelEditSlice"
import { AppDispatch } from "../store/store"
import { AddObjectModal } from "./AddObjectModal"
import { CommandsListView } from "./CommandsList"
import { DataModelObjectInfoModal } from "./DataModelObjectInfoModal"
import { DataModelView } from "./DataModelView"
import { ManagementConfigView } from "./ManagementConfigView"
import { ManagementView } from "./ManagementView"
import { Notifications } from "./Notifications"

const CpeView = () => {
    const { serialNumber } = useParams()
    const changeSet = useSelector(selectChangeSet)
    const dataModel = useSelector(selectDataModel)
    const hasEnqueued = useSelector(selectHasEnqueued)

    const dispatch = useDispatch<AppDispatch>()

    const updateParams = async () => {
        if (serialNumber === undefined) {
            console.log("Undefined Serial Number")
            return
        }
        await dispatch(
            sendCommand({
                id: uuidv4(),
                serialNumber: serialNumber,
                command: {
                    type: CommandType.Set,
                    data: {
                        paths: changeSet,
                    },
                },
            }),
        )
        await dispatch(clearDataModelEdit())
    }

    const getSupportedDM = async () => {
        if (serialNumber === undefined) {
            console.log("Undefined Serial Number")
            return
        }
        await dispatch(
            sendCommand({
                id: uuidv4(),
                serialNumber: serialNumber,
                command: {
                    type: CommandType.GetSupportedDM,
                    data: {
                        objPaths: ["Device."],
                    },
                },
            }),
        )
    }

    const refreshDataModel = async (paths: Array<string>) => {
        if (serialNumber === undefined) {
            return
        }
        await dispatch(clear())
        await getSupportedDM()
        await dispatch(
            sendCommand({
                id: uuidv4(),
                serialNumber: serialNumber,
                command: {
                    type: CommandType.Get,
                    data: {
                        paths: paths,
                    },
                },
            }),
        )
        await dispatch(clearDataModelEdit())
    }

    useEffect(() => {
        refreshDataModel(["Device.DeviceInfo."])

        // Clear data model on unmount
        return () => {
            dispatch(clear())
        }
    }, [])

    const panes = [
        {
            menuItem: (
                <Menu.Item key="management">
                    <Icon name="cogs" />
                    Management
                </Menu.Item>
            ),
            pane: (
                <TabPane attached={false} key="management-pane">
                    {serialNumber && <ManagementView serialNumber={serialNumber} />}
                </TabPane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="data-model">
                    <Icon name="database" />
                    Data Model
                </Menu.Item>
            ),
            pane: (
                <TabPane attached={false} key="data-model">
                    {Object.keys(changeSet).length > 0 && (
                        <Segment>
                            <Header as="h3">Change Set</Header>
                            <Table>
                                <Table.Header>
                                    <Table.HeaderCell>Path</Table.HeaderCell>
                                    <Table.HeaderCell>New Value</Table.HeaderCell>
                                </Table.Header>
                                {Array.from(Object.entries(changeSet), ([path, params]) =>
                                    Array.from(Object.entries(params), ([param, value]) => (
                                        <Table.Row>
                                            <Table.Cell>
                                                {path}
                                                {param}
                                            </Table.Cell>
                                            <Table.Cell>{value}</Table.Cell>
                                        </Table.Row>
                                    )),
                                )}
                            </Table>
                            <div style={{ display: "flex" }}>
                                <Button style={{ marginLeft: "auto" }} onClick={(_) => updateParams()} animated>
                                    <Button.Content visible>Apply</Button.Content>
                                    <Button.Content hidden>
                                        <Icon name="sync" />
                                    </Button.Content>
                                </Button>
                            </div>
                        </Segment>
                    )}

                    {dataModel != null && <DataModelView serialNumber={serialNumber!} />}
                </TabPane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="commands">
                    <Icon name="browser" />
                    Commands
                </Menu.Item>
            ),
            pane: (
                <TabPane attached={false} key="commands">
                    <CommandsListView />
                </TabPane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="notifyEvents">
                    <Icon name="bell" />
                    Notifications
                </Menu.Item>
            ),
            pane: (
                <TabPane attached={false} key="notifyEvents">
                    <Notifications serialNumber={serialNumber!} />
                </TabPane>
            ),
        },
        {
            menuItem: (
                <Menu.Item key="management-config">
                    <Icon name="wrench" />
                    Configuration
                </Menu.Item>
            ),
            pane: (
                <TabPane attached={false} key="managementConfig">
                    <ManagementConfigView serialNumber={serialNumber!} />
                </TabPane>
            ),
        },
    ]

    return (
        <>
            <DataModelObjectInfoModal />
            <AddObjectModal serialNumber={serialNumber!} />
            <Segment>
                <Header as="h3">Device Info</Header>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Endpoint ID:</b> {serialNumber}
                        </Grid.Column>
                        <Grid.Column>
                            <b>Serial Number:</b> {getPathOnDM(dataModel, "Device.DeviceInfo.", "SerialNumber")}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <b>Manufacturer:</b> {getPathOnDM(dataModel, "Device.DeviceInfo.", "Manufacturer")}
                        </Grid.Column>
                        <Grid.Column>
                            <b>Model:</b> {getPathOnDM(dataModel, "Device.DeviceInfo.", "ModelName")}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <div style={{ display: "flex" }}>
                    {!hasEnqueued && (
                        <Popup
                            content="Refresh all, may take a while"
                            position="bottom center"
                            trigger={
                                <Button
                                    style={{ marginLeft: "auto" }}
                                    icon="sync"
                                    onClick={(_) => refreshDataModel(["Device."])}
                                    circular
                                />
                            }
                        />
                    )}
                    {hasEnqueued && <Loader active inline style={{ marginLeft: "auto" }} />}
                </div>
            </Segment>

            <Tab menu={{ pointing: true }} panes={panes} renderActiveOnly={false} />
        </>
    )
}

export default CpeView
