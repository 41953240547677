import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Dimmer,
    Form,
    FormInput,
    Grid,
    GridColumn,
    GridRow,
    Header,
    Loader,
    Message,
    Modal,
    ModalActions,
    ModalContent,
    ModalHeader,
} from "semantic-ui-react"
import {
    getOpal2BandsteerParameters,
    Opal2BandsteerModifiableParameters,
    restoreOpal2BandsteerParameters,
    selectBandsteering,
    updateOpal2BandsteerParameters,
} from "../store/slices/bandsteeringSlice"
import { AppDispatch } from "../store/store"

interface ParamEditState {
    value: string
    error?: { content: string; pointing: string }
}

type Opal2BandsteerParametersEditState = {
    [key in keyof Opal2BandsteerModifiableParameters]?: ParamEditState
}

export const BandsteeringSettingsView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const bandsteeringData = useSelector(selectBandsteering)
    const params = bandsteeringData.parameters

    useEffect(() => {
        dispatch(getOpal2BandsteerParameters({ serialNumber: serialNumber }))
    }, [serialNumber])

    const [dirty, setDirty] = useState(false)
    const [editData, setEditData] = useState<Opal2BandsteerParametersEditState>({})
    const [restoreModalOpen, setRestoreModalOpen] = useState(false)

    const updateData = (data: Opal2BandsteerParametersEditState) => {
        setEditData(data)
        setDirty(true)
    }

    const discardEditData = () => {
        setEditData({})
        setDirty(false)
    }

    useEffect(() => {
        discardEditData()
    }, [bandsteeringData.parameters])

    const updateParameters = () => {
        const params = Object.fromEntries(Object.entries(editData).map(([key, data]) => [key, Number(data.value)]))
        dispatch(
            updateOpal2BandsteerParameters({
                serialNumber: serialNumber,
                data: params,
            }),
        )
    }

    const restoreParameters = () => {
        dispatch(
            restoreOpal2BandsteerParameters({
                serialNumber: serialNumber,
            }),
        )
        discardEditData()
    }

    const validateInput = (value: string) => ({
        value: value,
        error:
            Number(value) && !value.includes(".")
                ? undefined
                : {
                      content: "Invalid value",
                      pointing: "above",
                  },
    })

    return (
        <div>
            <Dimmer inverted active={bandsteeringData.loading}>
                <Loader active={bandsteeringData.loading} content="Loading" />
            </Dimmer>
            <Form success error>
                <Header>Bandsteer Configuration</Header>
                {bandsteeringData.lastSuccess && (
                    <Message success header="Success" content={bandsteeringData.lastSuccess} />
                )}
                {bandsteeringData.lastError && <Message error header="Error" content={bandsteeringData.lastError} />}
                <Grid divided="vertically">
                    <GridRow columns={2}>
                        <GridColumn>
                            <FormInput
                                label="Phy Rate Drop"
                                key="phy-rate-drop"
                                value={editData.phyRateDrop?.value || params?.phyRateDrop}
                                error={editData.phyRateDrop?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, phyRateDrop: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="Current THRCPI"
                                key={"curr-thrcpi"}
                                value={editData.currThrcpi?.value || params?.currThrcpi}
                                error={editData.currThrcpi?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, currThrcpi: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="Rate Gain"
                                key={"rate-gain"}
                                value={editData.rateGain?.value || params?.rateGain}
                                error={editData.rateGain?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, rateGain: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="Stale Age RCPI"
                                key="stale-age-rcpi"
                                value={editData.staleAgeRcpi?.value || params?.staleAgeRcpi}
                                error={editData.staleAgeRcpi?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, staleAgeRcpi: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="BTM Steering Disallowed Timer"
                                key="btm-steering-disallowed-timer"
                                value={editData.btmSteeringDisallowedTimer?.value || params?.btmSteeringDisallowedTimer}
                                error={editData.btmSteeringDisallowedTimer?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, btmSteeringDisallowedTimer: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="Non-steerable Timer"
                                key="non-steerable-timer"
                                value={editData.nonSteerableTimer?.value || params?.nonSteerableTimer}
                                error={editData.nonSteerableTimer?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, nonSteerableTimer: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="BTM Steering Disallowed STA List"
                                key="btm-steering-disallowed-sta-list"
                                value={params?.btmSteeringDisallowedList}
                            />
                        </GridColumn>
                        <GridColumn>
                            <FormInput
                                label="11v Reject Count"
                                key="count-11v-reject"
                                value={editData.count11vReject?.value || params?.count11vReject}
                                error={editData.count11vReject?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, count11vReject: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="11v Timeout Count"
                                key="count-11v-timeout"
                                value={editData.count11vTimeout?.value || params?.count11vTimeout}
                                error={editData.count11vTimeout?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, count11vTimeout: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="5GHz Far RSSI"
                                key="far-rssi-5g"
                                value={editData.farRssi5G?.value || params?.farRssi5G}
                                error={editData.farRssi5G?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, farRssi5G: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="RSSI 5GHz to 2.4GHz"
                                key="rssi-5g-to-2g"
                                value={editData.rssi5Gto2G?.value || params?.rssi5Gto2G}
                                error={editData.rssi5Gto2G?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, rssi5Gto2G: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="RSSI 6GHz to 2.4GHz"
                                key="rssi-6g-to-2g"
                                value={editData.rssi6Gto2G?.value || params?.rssi6Gto2G}
                                error={editData.rssi6Gto2G?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, rssi6Gto2G: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="RSSI 6GHz to 5GHz"
                                key="rssi-6g-to-5g"
                                value={editData.rssi6Gto5G?.value || params?.rssi6Gto5G}
                                error={editData.rssi6Gto5G?.error}
                                onChange={(ev, data) => {
                                    updateData({ ...editData, rssi6Gto5G: validateInput(data.value) })
                                }}
                            />
                            <FormInput
                                label="Unfriendly STA List"
                                key="unfriendly-sta-list"
                                value={params?.unfriendlyStaList}
                            />
                        </GridColumn>
                    </GridRow>
                </Grid>

                <div style={{ display: "flex" }}>
                    {dirty && (
                        <>
                            <Button
                                onClick={updateParameters}
                                disabled={!Object.values(editData).every((value) => !value.error)}
                            >
                                Save
                            </Button>
                            <Button onClick={discardEditData}>Discard</Button>
                        </>
                    )}
                    <Button style={{ marginLeft: "auto" }} onClick={() => setRestoreModalOpen(true)}>
                        Restore Defaults
                    </Button>
                </div>
                <Modal open={restoreModalOpen} onClose={() => setRestoreModalOpen(false)}>
                    <ModalHeader>Restore Bandsteering Parameters</ModalHeader>
                    <ModalContent>Are you sure to restore bandsteering parameters to default values?</ModalContent>
                    <ModalActions>
                        <Button negative onClick={() => setRestoreModalOpen(false)}>
                            No
                        </Button>
                        <Button
                            positive
                            onClick={() => {
                                setRestoreModalOpen(false)
                                restoreParameters()
                            }}
                        >
                            Yes
                        </Button>
                    </ModalActions>
                </Modal>
            </Form>
        </div>
    )
}
