import { ReactNode } from "react"

export function Center({ children }: { children: ReactNode }) {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
            }}
        >
            {children}
        </div>
    )
}
