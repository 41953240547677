import { Grid, Tab, TabPane } from "semantic-ui-react"
import { SettingsView } from "./SettingsView"

export const AdminView = () => {
    return (
        <Grid columns={3}>
            <Grid.Column width={1} />
            <Grid.Column width={14}>
                <Tab
                    panes={[
                        {
                            menuItem: "Global Settings",
                            render: () => (
                                <TabPane>
                                    <SettingsView />
                                </TabPane>
                            ),
                        },
                    ]}
                />
            </Grid.Column>
            <Grid.Column width={1} />
        </Grid>
    )
}
