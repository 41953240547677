import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Table } from "semantic-ui-react"
import {
    GlobalSetting,
    listGlobalSettings,
    selectSettings,
    updateGlobalSettings,
} from "../../store/slices/settingsSlice"
import { AppDispatch } from "../../store/store"

export const SettingsRow = ({ setting }: { setting: GlobalSetting }) => {
    const [editValue, setEditValue] = useState<string | null>(null)
    const dispatch = useDispatch<AppDispatch>()

    return (
        <Table.Row>
            <Table.Cell>{setting.path}</Table.Cell>
            <Table.Cell>
                {!editValue ? (
                    setting.value
                ) : (
                    <Form>
                        <Form.Input value={editValue} onChange={(e) => setEditValue(e.target.value)} />
                    </Form>
                )}
            </Table.Cell>
            <Table.Cell>
                {!editValue ? (
                    <Button onClick={() => setEditValue(setting.value)}>Edit</Button>
                ) : (
                    <>
                        <Button
                            onClick={() => {
                                dispatch(
                                    updateGlobalSettings({
                                        updates: [
                                            {
                                                path: setting.path,
                                                value: editValue,
                                            },
                                        ],
                                    }),
                                )
                                setEditValue(null)
                            }}
                        >
                            Save
                        </Button>
                        <Button onClick={() => setEditValue(null)}>Cancel</Button>
                    </>
                )}
            </Table.Cell>
        </Table.Row>
    )
}

export const SettingsView = () => {
    const dispatch = useDispatch<AppDispatch>()
    const settings = useSelector(selectSettings)

    useEffect(() => {
        dispatch(listGlobalSettings({}))
    }, [])

    const rows = Object.entries(settings.globalSettings).map(([_, setting]) => {
        return <SettingsRow setting={setting} />
    })

    return (
        <>
            <Table>
                <Table.Header>
                    <Table.HeaderCell width={8}>Path</Table.HeaderCell>
                    <Table.HeaderCell width={6}>Value</Table.HeaderCell>
                    <Table.HeaderCell width={2}></Table.HeaderCell>
                </Table.Header>
                <Table.Body>{rows}</Table.Body>
            </Table>
        </>
    )
}
