import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Accordion, Button, Form, Icon, Menu, MenuItem, Popup, Table } from "semantic-ui-react"
import {
    CommandType,
    DataModel,
    SupportedObject,
    createCommandAction,
    selectDataModel,
    selectSupportedDM,
    setObjectForInfo,
} from "../store/slices/commandSlice"
import { setObjectBeingAdded, updateParam } from "../store/slices/dataModelEditSlice"
import { AppDispatch } from "../store/store"
import { isObjectAddable, isObjectDeletable } from "../utils/utils"

const intRegex = /^\d+$/
const indexRegex = /\.\d+\./g
const indexReplacement = ".{i}."
const indexPlaceholder = "{i}."

function createDataModelViewHelper(
    serialNumber: string,
    dispatch: AppDispatch,
    dataModel: DataModel,
    supportedDM: Record<string, SupportedObject>,
    key: string,
    path: string = "",
    instanceOf: DataModel = {},
    isMultiInstance: boolean = false,
): any {
    let leafValues = []
    let panels = []

    let isInstance = isMultiInstance

    //TODO: Find a better way to merge DM and supported DM
    let merged = { ...instanceOf, ...dataModel }

    for (let key of Object.keys(merged).sort()) {
        if (key === "{i}") {
            continue
        }
        const value = merged[key]
        const isLeaf = typeof value === "string"
        let childPath = isLeaf ? `${path}${key}` : `${path}${key}.`

        if (typeof value === "string") {
            leafValues.push(<DataModelValueView paramName={key} paramValue={value} objectPath={childPath} />)
        } else {
            let isMultiInstanceChild = intRegex.test(key)
            let childInstanceOf = (isMultiInstanceChild ? instanceOf["{i}"] : instanceOf[key]) as DataModel
            panels.push(
                createDataModelViewHelper(
                    serialNumber,
                    dispatch,
                    value,
                    supportedDM,
                    key,
                    childPath,
                    childInstanceOf,
                    isMultiInstanceChild,
                ),
            )
        }
    }

    if (!path) {
        return <Accordion panels={panels} exclusive={false} styled={true} fluid />
    }

    let definitionPath = path.replace(indexRegex, indexReplacement)
    let definition = supportedDM[definitionPath]
    if (!definition && !isInstance) {
        definitionPath += indexPlaceholder
        definition = supportedDM[definitionPath]
    }

    return {
        key: path,
        title: {
            content: <Popup content={path} position="right center" trigger={<b>{key}</b>} />,
        },
        content: {
            content: (
                <>
                    {definition && (
                        <Menu compact size="mini">
                            <Popup
                                content="Info"
                                position="bottom center"
                                trigger={
                                    <MenuItem
                                        name="info"
                                        onClick={() => {
                                            dispatch(setObjectForInfo(definition))
                                        }}
                                    >
                                        <Icon name="info" />
                                    </MenuItem>
                                }
                            />
                            <Popup
                                content="Copy path to clipboard"
                                position="bottom center"
                                trigger={
                                    <MenuItem
                                        name="clipboard"
                                        onClick={() => {
                                            navigator.clipboard.writeText(path)
                                        }}
                                    >
                                        <Icon name="clipboard" />
                                    </MenuItem>
                                }
                            />
                            <Popup
                                content="Refresh"
                                position="bottom center"
                                trigger={
                                    <MenuItem
                                        name="refresh"
                                        onClick={() => {
                                            dispatch(
                                                createCommandAction(serialNumber, CommandType.Get, {
                                                    paths: [path],
                                                }),
                                            )
                                        }}
                                    >
                                        <Icon name="refresh" />
                                    </MenuItem>
                                }
                            />
                            {!isInstance && isObjectAddable(definition.access) && (
                                <Popup
                                    content="Add New Instance"
                                    position="bottom center"
                                    trigger={
                                        <MenuItem
                                            name="add"
                                            onClick={() => {
                                                dispatch(
                                                    setObjectBeingAdded({
                                                        path,
                                                        definition,
                                                    }),
                                                )
                                            }}
                                        >
                                            <Icon name="add" />
                                        </MenuItem>
                                    }
                                />
                            )}
                            {isInstance && isObjectDeletable(definition.access) && (
                                <Popup
                                    content="Delete This Instance"
                                    position="bottom center"
                                    trigger={
                                        <MenuItem
                                            name="delete"
                                            onClick={() => {
                                                dispatch(
                                                    createCommandAction(serialNumber, CommandType.Delete, {
                                                        paths: [path],
                                                    }),
                                                )
                                            }}
                                        >
                                            <Icon name="delete" />
                                        </MenuItem>
                                    }
                                />
                            )}
                        </Menu>
                    )}

                    {leafValues.length > 0 && <Table>{leafValues}</Table>}
                    {panels.length > 0 && <Accordion.Accordion panels={panels} exclusive={false} />}
                </>
            ),
        },
    }
}

const DataModelValueView = ({
    objectPath,
    paramName,
    paramValue,
}: {
    objectPath: string
    paramName: string
    paramValue: string
}) => {
    const [editValue, setEditValue] = useState<string | null>(null)
    const [currValue, setCurrValue] = useState<string>(paramValue)

    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        setCurrValue(paramValue)
    }, [paramValue])

    let editMode = editValue !== null

    return (
        <Table.Row>
            <Table.Cell>{paramName}</Table.Cell>
            <Table.Cell>
                {!editMode ? (
                    <>{currValue}</>
                ) : (
                    <Form>
                        <Form.Input value={editValue} onChange={(e) => setEditValue(e.target.value)} />
                    </Form>
                )}
            </Table.Cell>
            <Table.Cell>
                {!editMode ? (
                    <Button onClick={() => setEditValue(currValue)}>Edit</Button>
                ) : (
                    <>
                        <Button
                            onClick={() => {
                                if (editValue !== null) {
                                    dispatch(
                                        updateParam({
                                            path: objectPath,
                                            value: editValue,
                                        }),
                                    )
                                    setCurrValue(editValue)
                                    setEditValue(null)
                                }
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={() => {
                                setEditValue(null)
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                )}
            </Table.Cell>
        </Table.Row>
    )
}

export const DataModelView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch()
    const dataModel = useSelector(selectDataModel)
    const supportedDM = useSelector(selectSupportedDM)
    const [nestedDM, setNestedDM] = useState<DataModel>({})

    useEffect(() => {
        let nestedDM: DataModel = {}
        for (const [path, params] of Object.entries(dataModel)) {
            let curr = nestedDM
            for (const segment of path.split(".")) {
                if (segment === "") {
                    continue
                }
                let currSegment = curr[segment] as DataModel
                if (currSegment === undefined) {
                    currSegment = {}
                    curr[segment] = currSegment
                }
                curr = currSegment
            }
            for (const [param, value] of Object.entries(params)) {
                curr[param] = value
            }
        }

        for (const path of Object.keys(supportedDM)) {
            let curr = nestedDM
            for (const segment of path.split(".")) {
                if (segment === "") {
                    continue
                }
                let currSegment = curr[segment] as DataModel
                if (currSegment === undefined) {
                    currSegment = {}
                    curr[segment] = currSegment
                }
                curr = currSegment
            }
        }
        setNestedDM(nestedDM)
    }, [dataModel, supportedDM])

    return createDataModelViewHelper(serialNumber, dispatch, nestedDM, supportedDM, "", "", nestedDM, false)
}
