import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export const CPES_PAGE_SIZE = 30

export interface Cpe {
    eid: string
    serialNumber: string
    lastSeen: string
    active: boolean
}

export interface ListedCpesState {
    loading: boolean
    count: number
    devices: Cpe[]
    onlyActive: boolean
    activePage: number
    totalPages: number
}

const createInitialState = (): ListedCpesState => {
    return {
        loading: false,
        count: 0,
        devices: [],
        onlyActive: true,
        activePage: 1,
        totalPages: 1,
    }
}

export interface ListCpesRequest {
    page: number
    onlyActive: boolean
}

export interface ListCpesResponse {
    count: number
    devices: Cpe[]
}

export const fetchCpesList = createAsyncThunk("fetchCpes", async (payload: ListCpesRequest, { rejectWithValue }) => {
    let params = new URLSearchParams({
        limit: String(CPES_PAGE_SIZE),
        offset: String((payload.page - 1) * CPES_PAGE_SIZE),
        onlyActive: String(payload.onlyActive),
    })

    let rsp = await tryFetch("/acs/cpes?" + params, {
        method: "GET",
    })
    let body = await rsp.json()
    if (rsp.status >= 400) {
        return rejectWithValue(body)
    }
    return body as ListCpesResponse
})

export const cpesSlice = createSlice({
    name: "cpes",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCpesList.pending, (state, action: any) => {
            state.loading = true
        })
        builder.addCase(fetchCpesList.fulfilled, (state, action) => {
            let totalCount = action.payload.count
            state.count = totalCount
            state.totalPages = Math.ceil(totalCount / CPES_PAGE_SIZE)
            state.activePage = action.meta.arg.page
            state.onlyActive = action.meta.arg.onlyActive
            state.devices = action.payload.devices
            state.loading = false
        })
        builder.addCase(fetchCpesList.rejected, (state, action: any) => {
            state.loading = false
        })
    },
})

export const cpesReducer = cpesSlice.reducer

export const selectListedCpes = (state: RootState) => state.cpes
