export function isObjectDeletable(objAccess: string): boolean {
    return objAccess === "OBJ_ADD_DELETE" || objAccess === "OBJ_DELETE_ONLY"
}

export function isObjectAddable(objAccess: string): boolean {
    return objAccess === "OBJ_ADD_DELETE" || objAccess === "OBJ_ADD_ONLY"
}

export function isParamWritable(paramAccess: string): boolean {
    return paramAccess === "PARAM_READ_WRITE" || paramAccess === "PARAM_WRITE_ONLY"
}

export function boolToYesNo(p: boolean): string {
    return p ? "Yes" : "No"
}

export function getParamAccessRepr(paramAccess: string): string {
    switch (paramAccess) {
        case "PARAM_READ_WRITE":
            return "Read/Write"
        case "PARAM_WRITE_ONLY":
            return "Write Only"
        case "PARAM_READ_ONLY":
            return "Read Only"
    }
    return "Unknown"
}

export function getParamTypeRepr(type: string): string {
    switch (type) {
        case "PARAM_BASE_64":
            return "base64"
        case "PARAM_BOOLEAN":
            return "boolean"
        case "PARAM_DATE_TIME":
            return "datetime"
        case "PARAM_DECIMAL":
            return "decimal"
        case "PARAM_HEX_BINARY":
            return "hexBinary"
        case "PARAM_INT":
            return "int"
        case "LONG":
            return "long"
        case "PARAM_STRING":
            return "string"
        case "PARAM_UNSIGNED_INT":
            return "unsigned int"
        case "PARAM_UNSIGNED_LONG":
            return "unsigned long"
    }
    return "Unknown"
}

export function getParamValueChangeRepr(valueChange: string): string {
    switch (valueChange) {
        case "VALUE_CHANGE_ALLOWED":
            return "Supported"
        case "VALUE_CHANGE_WILL_IGNORE":
            return "Ignored"
    }
    return "Unknown"
}

export function getCommandTypeRepr(type: string): string {
    switch (type) {
        case "CMD_SYNC":
            return "Sync"
        case "CMD_ASYNC":
            return "Async"
    }
    return "Unknown"
}

export function arrayEquals<T>(a: T[], b: T[]) {
    return a.length === b.length && a.every((val, index) => val === b[index])
}
