import { useDispatch, useSelector } from "react-redux"
import { Accordion, Modal, Tab, TabPane, Table } from "semantic-ui-react"
import { selectCurrentObjectForInfo, setObjectForInfo } from "../store/slices/commandSlice"
import {
    boolToYesNo,
    getCommandTypeRepr,
    getParamAccessRepr,
    getParamTypeRepr,
    getParamValueChangeRepr,
    isObjectAddable,
    isObjectDeletable,
} from "../utils/utils"

export const DataModelObjectInfoModal = () => {
    const dispatch = useDispatch()
    const currentObject = useSelector(selectCurrentObjectForInfo)

    const commandPanels = currentObject?.supportedCommands.map((command) => {
        let panes = []
        if (command.inputArgs.length > 0) {
            panes.push({
                menuItem: "Input Arguments",
                render: () => (
                    <TabPane>
                        <Table>
                            {command.inputArgs.map((param) => (
                                <Table.Row key={param}>
                                    <Table.Cell>{param}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table>
                    </TabPane>
                ),
            })
        }
        if (command.outputArgs.length > 0) {
            panes.push({
                menuItem: "Output Arguments",
                render: () => (
                    <TabPane>
                        <Table>
                            {command.outputArgs.map((param) => (
                                <Table.Row key={param}>
                                    <Table.Cell>{param}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table>
                    </TabPane>
                ),
            })
        }

        return {
            key: command.name,
            title: {
                content: command.name,
            },
            content: {
                content: (
                    <>
                        <p>
                            <b>Type</b>: {getCommandTypeRepr(command.commandType)}
                        </p>
                        {panes.length > 0 && <Tab panes={panes} />}
                    </>
                ),
            },
        }
    })

    const eventPanels = currentObject?.supportedEvents.map((event) => {
        return {
            key: event.name,
            title: {
                content: event.name,
            },
            content: {
                content: (
                    <>
                        {event.args.length === 0 ? (
                            <>Has no arguments</>
                        ) : (
                            <Table>
                                {event.args.map((param) => (
                                    <Table.Row key={param}>
                                        <Table.Cell>{param}</Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table>
                        )}
                    </>
                ),
            },
        }
    })

    return (
        <>
            <Modal
                onClose={() => {
                    dispatch(setObjectForInfo(null))
                }}
                open={currentObject != null}
            >
                {currentObject !== null && (
                    <>
                        <Modal.Header>{currentObject.path}</Modal.Header>
                        <Modal.Content scrolling>
                            <Tab
                                menu={{ pointing: true }}
                                panes={[
                                    {
                                        menuItem: "Parameters",
                                        render: () => (
                                            <>
                                                <p>
                                                    <b>Multi Instance:</b> {boolToYesNo(currentObject.isMultiInstance)}
                                                </p>
                                                <p>
                                                    <b>Can Add:</b> {boolToYesNo(isObjectAddable(currentObject.access))}
                                                </p>
                                                <p>
                                                    <b>Can Delete:</b>{" "}
                                                    {boolToYesNo(isObjectDeletable(currentObject.access))}
                                                </p>
                                                {currentObject.supportedParams.length === 0 ? (
                                                    <p>Has no parameters</p>
                                                ) : (
                                                    <Table>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                {["Name", "Access", "Type", "Value Change"].map(
                                                                    (col) => (
                                                                        <Table.HeaderCell>{col}</Table.HeaderCell>
                                                                    ),
                                                                )}
                                                            </Table.Row>
                                                        </Table.Header>
                                                        {currentObject.supportedParams.map((param) => (
                                                            <Table.Row key={param.name}>
                                                                <Table.Cell>{param.name}</Table.Cell>
                                                                <Table.Cell>
                                                                    {getParamAccessRepr(param.access)}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {getParamTypeRepr(param.valueType)}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {getParamValueChangeRepr(param.valueChange)}
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                    </Table>
                                                )}
                                            </>
                                        ),
                                    },
                                    {
                                        menuItem: "Commands",
                                        render: () => (
                                            <>
                                                {currentObject.supportedCommands.length === 0 ? (
                                                    <p>Has no commands</p>
                                                ) : (
                                                    <Accordion styled panels={commandPanels} />
                                                )}
                                            </>
                                        ),
                                    },
                                    {
                                        menuItem: "Events",
                                        render: () => (
                                            <>
                                                {currentObject.supportedEvents.length === 0 ? (
                                                    <p>Has no events</p>
                                                ) : (
                                                    <Accordion styled panels={eventPanels} />
                                                )}
                                            </>
                                        ),
                                    },
                                ]}
                            />
                        </Modal.Content>
                    </>
                )}
            </Modal>
        </>
    )
}
