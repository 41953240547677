import { Header, Icon, Menu, Segment, Tab, TabPane } from "semantic-ui-react"
import { ParentalControlView } from "./ParentalControlView"
import { WifiConfigView } from "./WifiConfigView"
import { ClientsView } from "./ClientsView"

// TODO: Check support for management functionality using another method rather than serial prefix control
const ZYXEL_SERIAL_PREFIX = "S2"

export const ManagementView = ({ serialNumber }: { serialNumber: string }) => {
    return (
        <>
            {!serialNumber.startsWith(ZYXEL_SERIAL_PREFIX) ? (
                <Segment placeholder>
                    <Header icon>
                        <Icon name="frown outline" />
                        Not supported by Management API.
                    </Header>
                </Segment>
            ) : (
                <Tab
                    menu={{ fluid: true, vertical: true }}
                    grid={{ paneWidth: 14, tabWidth: 2 }}
                    panes={[
                        {
                            menuItem: (
                                <Menu.Item key="clients">
                                    <Icon name="laptop" />
                                    Clients
                                </Menu.Item>
                            ),
                            render: () => (
                                <TabPane>
                                    <ClientsView serialNumber={serialNumber} />
                                </TabPane>
                            ),
                        },
                        {
                            menuItem: (
                                <Menu.Item key="parental-control">
                                    <Icon name="child" />
                                    Parental Control
                                </Menu.Item>
                            ),
                            render: () => (
                                <TabPane>
                                    <ParentalControlView serialNumber={serialNumber}></ParentalControlView>
                                </TabPane>
                            ),
                        },
                        {
                            menuItem: (
                                <Menu.Item key="wifi">
                                    <Icon name="wifi" />
                                    Wi-Fi
                                </Menu.Item>
                            ),
                            render: () => (
                                <TabPane>
                                    <Header>Wi-Fi</Header>
                                    <WifiConfigView serialNumber={serialNumber} />
                                </TabPane>
                            ),
                        },
                    ]}
                />
            )}
        </>
    )
}
