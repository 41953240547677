import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../store"
import { SupportedObject } from "./commandSlice"

interface DataModelChangeSet {
    changes: Record<string, Record<string, string>>
    currentObjectBeingAdded: ObjectAddState | null
}

const createInitialState = (): DataModelChangeSet => {
    return {
        changes: {},
        currentObjectBeingAdded: null,
    }
}

interface DataModelUpdateAction {
    path: string
    value: string
}

interface ObjectAddState {
    path: string
    definition: SupportedObject
}

const parsePath = (path: string) => {
    const lastDotPos = path.lastIndexOf(".")
    return [path.slice(0, lastDotPos + 1), path.slice(lastDotPos + 1)]
}

export const dataModelEditSlice = createSlice({
    name: "dataModelEdit",
    initialState: createInitialState(),
    reducers: {
        updateParam: (state, action: PayloadAction<DataModelUpdateAction>) => {
            const [path, param] = parsePath(action.payload.path)
            let params = state.changes[path]
            if (params === undefined) {
                params = {}
                state.changes[path] = params
            }
            params[param] = action.payload.value
        },

        setObjectBeingAdded: (state, action: PayloadAction<ObjectAddState | null>) => {
            state.currentObjectBeingAdded = action.payload
        },

        clearDataModelEdit: (state) => {
            state.changes = {}
        },
    },
})

export const { updateParam, clearDataModelEdit, setObjectBeingAdded } = dataModelEditSlice.actions
export const dataModelEditReducer = dataModelEditSlice.reducer

export const selectDataModelEditState = (state: RootState) => state.dataModelEdit
export const selectChangeSet = (state: RootState) => state.dataModelEdit.changes
export const selectObjectBeingAdded = (state: RootState) => state.dataModelEdit.currentObjectBeingAdded
