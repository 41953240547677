import { ReactNode, useState } from "react"
import { useDispatch } from "react-redux"
import {
    Button,
    ButtonGroup,
    Dropdown,
    Form,
    FormGroup,
    FormInput,
    Icon,
    ListContent,
    ListDescription,
    ListHeader,
    ListIcon,
    ListItem,
    Popup,
} from "semantic-ui-react"
import {
    DayOfWeek,
    Schedule,
    createSchedule,
    deleteSchedule,
    updateSchedule,
} from "../store/slices/parentalControlSlice"
import { AppDispatch } from "../store/store"

const hourInputs = [...Array(24)].map((_, i) => {
    const value = i.toString().padStart(2, "0")
    return {
        key: i,
        text: value,
        value: value,
    }
})

const minuteInputs = [...Array(60)].map((_, i) => {
    const value = i.toString().padStart(2, "0")
    return {
        key: i,
        text: value,
        value: value,
    }
})

export const DaysOfWeekSelect = ({
    value,
    onClick,
}: {
    value: DayOfWeek[]
    onClick: (clickedValue: DayOfWeek) => void
}) => {
    return (
        <ButtonGroup circular size="mini">
            {Object.values(DayOfWeek).map((day: DayOfWeek) => {
                return (
                    <Button color={value.includes(day) ? "red" : "grey"} onClick={() => onClick(day)}>
                        {day.substring(0, 3)}
                    </Button>
                )
            })}
        </ButtonGroup>
    )
}

export const ScheduleEditView = ({
    serialNumber,
    profileId,
    schedule,
    trigger,
}: {
    serialNumber: string
    profileId: string
    schedule: Schedule | null
    trigger: ReactNode
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const [editDays, setEditDays] = useState<DayOfWeek[]>(schedule?.days || [])

    const [startMin, setStartMin] = useState<string>(schedule === null ? "00" : schedule.startTime.split(":")[1])
    const [startHour, setStartHour] = useState<string>(schedule === null ? "00" : schedule.startTime.split(":")[0])
    const [endMin, setEndMin] = useState<string>(schedule === null ? "00" : schedule.endTime.split(":")[1])
    const [endHour, setEndHour] = useState<string>(schedule === null ? "00" : schedule.endTime.split(":")[0])
    return (
        <Popup
            trigger={trigger}
            on="click"
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onOpen={() => setIsOpen(true)}
        >
            <Form>
                <FormInput label="Schedule">
                    <DaysOfWeekSelect
                        value={editDays}
                        onClick={(val) => {
                            editDays.includes(val)
                                ? setEditDays(editDays.filter((d) => d !== val))
                                : setEditDays([...editDays, val])
                        }}
                    />
                </FormInput>
                <FormGroup widths="equal">
                    <FormInput label="Start Time:">
                        <Dropdown
                            value={startHour}
                            compact
                            selection
                            scrolling
                            options={hourInputs}
                            onChange={(_, data) => setStartHour(data.value as string)}
                        />
                        <Dropdown
                            value={startMin}
                            compact
                            selection
                            scrolling
                            options={minuteInputs}
                            onChange={(_, data) => setStartMin(data.value as string)}
                        />
                    </FormInput>
                    <FormInput label="End Time:">
                        <Dropdown
                            value={endHour}
                            compact
                            selection
                            scrolling
                            options={hourInputs}
                            onChange={(_, data) => setEndHour(data.value as string)}
                        />
                        <Dropdown
                            value={endMin}
                            compact
                            selection
                            scrolling
                            options={minuteInputs}
                            onChange={(_, data) => setEndMin(data.value as string)}
                        />
                    </FormInput>
                </FormGroup>
                <Button
                    onClick={(_) => {
                        const data = {
                            days: editDays,
                            startTime: `${startHour}:${startMin}`,
                            endTime: `${endHour}:${endMin}`,
                            enabled: true,
                        }
                        if (schedule === null) {
                            dispatch(
                                createSchedule({
                                    serialNumber: serialNumber,
                                    profileId: profileId,
                                    parameters: data,
                                }),
                            )
                        } else {
                            dispatch(
                                updateSchedule({
                                    serialNumber: serialNumber,
                                    profileId: profileId,
                                    scheduleId: schedule.id,
                                    updates: data,
                                }),
                            )
                        }
                        setIsOpen(false)
                    }}
                >
                    Save
                </Button>
                <Button onClick={(_) => setIsOpen(false)}>Close</Button>
            </Form>
        </Popup>
    )
}

export default function ScheduleView({
    serialNumber,
    profileId,
    schedule,
    editMode,
}: {
    serialNumber: string
    profileId: string
    schedule: Schedule
    editMode: boolean
}) {
    const dispatch = useDispatch<AppDispatch>()

    let scheduleText = "No days specified"
    if (schedule.days.length === 7) {
        scheduleText = "Every day"
    } else {
        scheduleText = schedule.days.join(", ")
    }
    return (
        <ListItem>
            {editMode && (
                <ListContent floated="right">
                    <a
                        style={{ color: "black" }}
                        onClick={() =>
                            dispatch(
                                deleteSchedule({
                                    serialNumber: serialNumber,
                                    profileId: profileId,
                                    scheduleId: schedule.id,
                                }),
                            )
                        }
                    >
                        <Icon name="delete" size="large" />
                    </a>
                    <ScheduleEditView
                        serialNumber={serialNumber}
                        profileId={profileId}
                        schedule={schedule}
                        trigger={
                            <a style={{ color: "black" }}>
                                <Icon name="edit" size="large" />
                            </a>
                        }
                    />
                </ListContent>
            )}
            <ListIcon name="calendar alternate outline" />
            <ListContent>
                <ListHeader>
                    From {schedule.startTime} to {schedule.endTime}
                </ListHeader>
                <ListDescription>{scheduleText === "" ? "No days specified" : scheduleText}</ListDescription>
            </ListContent>
        </ListItem>
    )
}
