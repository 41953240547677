import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

interface ManagementConfigStatus {
    bulkDataEnabled: boolean | null
    bulkDataLoading: boolean
    lastError: string | null
}

function createInitialState(): ManagementConfigStatus {
    return {
        bulkDataEnabled: null,
        bulkDataLoading: false,
        lastError: null,
    }
}

export enum BulkDataOperationType {
    CheckEnabled = "HEAD",
    Enable = "PUT",
    Disable = "DELETE",
}

interface BulkDataOperationPayload {
    serialNumber: string
    operation: BulkDataOperationType
}

export const bulkDataOperation = createAsyncThunk(
    "managementConfig/bulkDataOperation",
    async (payload: BulkDataOperationPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/bulk-data`, {
            method: payload.operation,
            headers: {
                "Content-Length": "0",
            },
        })
        if (rsp.status === 204) {
            return true
        } else if (rsp.status === 404) {
            return false
        }
        return rejectWithValue(rsp.status)
    },
)

export function checkBulkDataEnabled(serialNumber: string) {
    return bulkDataOperation({
        serialNumber: serialNumber,
        operation: BulkDataOperationType.CheckEnabled,
    })
}

export function disableBulkData(serialNumber: string) {
    return bulkDataOperation({
        serialNumber: serialNumber,
        operation: BulkDataOperationType.Disable,
    })
}

export function enableBulkData(serialNumber: string) {
    return bulkDataOperation({
        serialNumber: serialNumber,
        operation: BulkDataOperationType.Enable,
    })
}

export const managementConfigSlice = createSlice({
    name: "managementConfig",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(bulkDataOperation.pending, (state, _) => {
            state.bulkDataLoading = true
        })
        builder.addCase(bulkDataOperation.fulfilled, (state, action) => {
            let enabled = false
            switch (action.meta.arg.operation) {
                case BulkDataOperationType.CheckEnabled:
                case BulkDataOperationType.Enable:
                    enabled = action.payload
                    break
                case BulkDataOperationType.Disable:
                    enabled = !action.payload
            }
            state.bulkDataEnabled = enabled
            state.bulkDataLoading = false
            state.lastError = null
        })
        builder.addCase(bulkDataOperation.rejected, (state, action) => {
            state.bulkDataLoading = false
            state.lastError = "Can not enable Bulk Data on this device"
        })
    },
})

export const managementConfigReducer = managementConfigSlice.reducer

export const selectManagementConfig = (state: RootState) => state.managementConfig
