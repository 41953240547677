import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export type HostType = "Client" | "Extender"

export interface Host {
    macAddress: string
    ipAddress: string
    addressSource: string
    name: string
    active: boolean
    type: HostType
}

interface HostsState {
    hosts: Host[]
    loading: boolean
}

const createInitialState = (): HostsState => {
    return {
        hosts: [],
        loading: false,
    }
}

export const fetchHosts = createAsyncThunk(
    "fetchHosts",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/hosts`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body
    },
)

export const hostsSlice = createSlice({
    name: "hostsSlice",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchHosts.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(fetchHosts.fulfilled, (state, action) => {
            state.loading = false
            state.hosts = action.payload
        })
        builder.addCase(fetchHosts.rejected, (state, action) => {
            state.loading = false
        })
    },
})

export const hostsReducer = hostsSlice.reducer
export const selectHosts = (state: RootState) => state.hostsInfo.hosts
export const selectHostsState = (state: RootState) => state.hostsInfo
