import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"

import { enableMapSet } from "immer"
import { Provider } from "react-redux"
import { createHashRouter, RouterProvider } from "react-router-dom"
import "semantic-ui-css/semantic.min.css"
import LoginPage from "./components/Login"
import { store } from "./store/store"

enableMapSet()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

const router = createHashRouter([
    {
        path: "/login",
        element: <LoginPage />,
    },
    {
        path: "/*",
        element: <App />,
    },
])

root.render(
    //<React.StrictMode>
    <Provider store={store}>
        <RouterProvider router={router} />
    </Provider>,
    //</React.StrictMode>,
)
