import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Form, Grid, Header, Icon, Message, Segment } from "semantic-ui-react"
import { AuthStatus, login, logout, selectAuthState } from "../store/slices/authSlice"
import { AppDispatch } from "../store/store"

const LoginPage = () => {
    const authState = useSelector(selectAuthState)
    const navigate = useNavigate()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const dispatch = useDispatch<AppDispatch>()

    const handleLogin = async () => {
        await dispatch(login({ username, password }))
    }

    useEffect(() => {
        const access = localStorage.getItem("access")
        if (access === null) {
            dispatch(logout())
        }
    }, [])

    useEffect(() => {
        if (authState.status === AuthStatus.LoggedIn) {
            navigate("/")
        }
    }, [authState])

    return (
        <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 400 }}>
                <Header as="h1">
                    <Icon name="cog" />
                    Warp Login
                </Header>
                <Segment stacked>
                    <Form size="large">
                        <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="Username"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button fluid size="large" onClick={(_) => handleLogin()}>
                            Login
                        </Button>
                    </Form>
                    {authState.loginError != null && (
                        <Message warning>
                            <Icon name="warning" />
                            {authState.loginError}
                        </Message>
                    )}
                </Segment>
            </Grid.Column>
        </Grid>
    )
}

export default LoginPage
