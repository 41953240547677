import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Breadcrumb,
    BreadcrumbDivider,
    BreadcrumbSection,
    Button,
    Dimmer,
    Header,
    HeaderContent,
    HeaderSubheader,
    Icon,
    Label,
    Loader,
    Segment,
    SemanticCOLORS,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react"
import {
    ConnectivityEvent,
    EventType,
    getClientConnectivityEvents,
    selectClientConnectivityEvents,
} from "../store/slices/clientConnectivitySlice"
import { fetchHosts, HostType, selectHostsState } from "../store/slices/hostsSlice"
import { AppDispatch } from "../store/store"
import { UNKNOWN_REPR } from "../utils/constants"

interface ClientData {
    macAddress: string
    name: string
    active: boolean
    ipAddress?: string
    addressSource?: string
    type?: HostType
    eventCount: number
    errorCount: number
    events: ConnectivityEvent[]
}

function getEventColor(eventType: EventType): SemanticCOLORS {
    switch (eventType) {
        case "Association":
            return "blue"
        case "Disassociation":
            return "teal"
        case "FailedConnection":
            return "red"
    }
    return "grey"
}

export const ClientDetailsView = ({ client }: { client: ClientData }) => {
    return (
        <>
            {client.events ? (
                <Table>
                    <TableHeader>
                        <TableHeaderCell>Event Type</TableHeaderCell>
                        <TableHeaderCell>Timestamp</TableHeaderCell>
                        <TableHeaderCell>BSSID</TableHeaderCell>
                        <TableHeaderCell>Status</TableHeaderCell>
                        <TableHeaderCell>Reason</TableHeaderCell>
                    </TableHeader>
                    {client.events
                        .slice()
                        .reverse()
                        .map((event) => {
                            return (
                                <TableRow>
                                    <TableCell>
                                        <Label color={getEventColor(event.type)}>{event.type}</Label>
                                    </TableCell>
                                    <TableCell>{new Date(event.timestamp).toLocaleString()}</TableCell>
                                    <TableCell>{event.bssid}</TableCell>
                                    <TableCell>
                                        {event.status}
                                        {event.statusCode != null && ` (${event.statusCode})`}
                                    </TableCell>
                                    <TableCell>
                                        {event.reason}
                                        {event.reasonCode != null && ` (${event.reasonCode})`}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                </Table>
            ) : (
                <Segment>No events</Segment>
            )}
        </>
    )
}

export const ClientsView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const clientConnectivityEvents = useSelector(selectClientConnectivityEvents)
    const hosts = useSelector(selectHostsState)
    const [currentClient, setCurrentClient] = useState<string | null>(null)

    const refreshData = () => {
        dispatch(getClientConnectivityEvents({ serialNumber: serialNumber }))
        dispatch(fetchHosts({ serialNumber: serialNumber }))
    }

    useEffect(() => {
        refreshData()
    }, [])

    const clientMap: Record<string, ClientData> = {}
    for (const host of hosts.hosts) {
        const events = clientConnectivityEvents.clients[host.macAddress]
        clientMap[host.macAddress] = {
            ...host,
            ...events,
        }
    }

    for (const [mac, events] of Object.entries(clientConnectivityEvents.clients)) {
        if (!clientMap[mac]) {
            clientMap[mac] = {
                name: UNKNOWN_REPR,
                active: false,
                ...events,
            }
        }
    }

    const clientData = Object.values(clientMap)
    const currentClientData = clientData.find((client) => client.macAddress === currentClient) || null
    const loading = clientConnectivityEvents.loading || hosts.loading
    return (
        <div>
            <Dimmer inverted active={loading}>
                <Loader active={loading} content="Loading" />
            </Dimmer>
            <div style={{ display: "flex" }}>
                <Breadcrumb size="big">
                    <b>
                        <BreadcrumbSection onClick={() => setCurrentClient(null)}>Clients</BreadcrumbSection>
                        {currentClientData && (
                            <>
                                <BreadcrumbDivider icon="right chevron" />
                                <BreadcrumbSection>{currentClientData.name}</BreadcrumbSection>
                            </>
                        )}
                    </b>
                </Breadcrumb>
                <Button circular style={{ marginLeft: "auto" }} icon="refresh" onClick={refreshData} />
            </div>
            {currentClientData === null ? (
                clientData.length ? (
                    <Table>
                        <TableHeader>
                            <TableHeaderCell>Name</TableHeaderCell>
                            <TableHeaderCell>IP Address</TableHeaderCell>
                            <TableHeaderCell>Total Events</TableHeaderCell>
                            <TableHeaderCell>Error Events</TableHeaderCell>
                        </TableHeader>
                        <TableBody>
                            {clientData.map((client) => {
                                return (
                                    <TableRow>
                                        <TableCell on>
                                            <Header as="h4" onClick={() => setCurrentClient(client.macAddress)}>
                                                <Icon
                                                    color={client.active ? "green" : "grey"}
                                                    name={client.type === "Extender" ? "wifi" : "laptop"}
                                                    link
                                                />
                                                <HeaderContent>
                                                    {client.name}
                                                    <HeaderSubheader>{client.macAddress}</HeaderSubheader>
                                                </HeaderContent>
                                            </Header>
                                        </TableCell>
                                        <TableCell>
                                            {client.ipAddress}
                                            {client.addressSource && ` (${client.addressSource})`}
                                        </TableCell>
                                        <TableCell>{client.eventCount || 0}</TableCell>
                                        <TableCell>{client.errorCount || 0}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                ) : (
                    <Segment>No active clients</Segment>
                )
            ) : (
                <ClientDetailsView client={currentClientData} />
            )}
        </div>
    )
}
