import { useEffect, useState } from "react"
import { Statistic } from "semantic-ui-react"
import { tryFetch } from "../utils/api"
import { Center } from "./Layout"

interface Stats {
    activeCount: number
    totalCount: number
}

export default function StatsView() {
    const [stats, setStats] = useState<Stats>()

    const fetchStats = async () => {
        tryFetch("/stats").then((rsp: any) => {
            if (rsp.status === 200) {
                setStats(rsp.json())
            } else {
                setStats({ activeCount: 0, totalCount: 0 })
            }
        })
    }

    useEffect(() => {
        fetchStats()
    }, [])

    return (
        <Center>
            <Statistic.Group>
                <Statistic>
                    <Statistic.Value>{stats?.activeCount}</Statistic.Value>
                    <Statistic.Label>Active CPEs</Statistic.Label>
                </Statistic>
                <Statistic>
                    <Statistic.Value>{stats?.totalCount}</Statistic.Value>
                    <Statistic.Label>Total CPEs</Statistic.Label>
                </Statistic>
            </Statistic.Group>
        </Center>
    )
}
