import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Checkbox, Grid, GridRow, Header, Icon, Message } from "semantic-ui-react"
import {
    checkBulkDataEnabled,
    disableBulkData,
    enableBulkData,
    selectManagementConfig,
} from "../store/slices/managementConfigSlice"
import { AppDispatch } from "../store/store"

export const ManagementConfigView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const managementConfig = useSelector(selectManagementConfig)

    useEffect(() => {
        dispatch(checkBulkDataEnabled(serialNumber))
    }, [])

    return (
        <>
            <Header>Management Configuration</Header>
            {managementConfig.lastError != null && (
                <Message warning>
                    <Icon name="warning" />
                    {managementConfig.lastError}
                </Message>
            )}
            <Grid columns="equal" padded={true}>
                <GridRow>
                    Enable Bulk Data
                    <Checkbox
                        style={{ marginLeft: ".5rem" }}
                        checked={managementConfig.bulkDataEnabled || false}
                        indeterminate={managementConfig.bulkDataEnabled === null}
                        disabled={managementConfig.bulkDataLoading}
                        onClick={() => {
                            if (!managementConfig.bulkDataLoading)
                                dispatch(
                                    managementConfig.bulkDataEnabled
                                        ? disableBulkData(serialNumber)
                                        : enableBulkData(serialNumber),
                                )
                        }}
                        toggle
                    />
                </GridRow>
            </Grid>
        </>
    )
}
