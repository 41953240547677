import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export interface Opal2BandsteerModifiableParameters {
    phyRateDrop: number
    currThrcpi: number
    rateGain: number
    count11vReject: number
    count11vTimeout: number
    farRssi5G: number
    rssi5Gto2G: number
    rssi6Gto2G: number
    rssi6Gto5G: number
    staleAgeRcpi: number
    btmSteeringDisallowedTimer: number
    nonSteerableTimer: number
}

export interface Opal2BandsteerParameters extends Opal2BandsteerModifiableParameters {
    btmSteeringDisallowedList: string
    unfriendlyStaList: string
}

type UpdateOpal2BandsteerParameters = {
    [key in keyof Opal2BandsteerModifiableParameters]?: number
}

export const getOpal2BandsteerParameters = createAsyncThunk(
    "getOpal2BandsteerParameters",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/bandsteering/parameters`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Opal2BandsteerParameters
    },
)

export interface UpdateOpal2BandsteerParametersPayload {
    serialNumber: string
    data: UpdateOpal2BandsteerParameters
}

export const updateOpal2BandsteerParameters = createAsyncThunk(
    "updateOpal2BandsteerParameters",
    async (payload: UpdateOpal2BandsteerParametersPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/bandsteering/parameters`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.data),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Opal2BandsteerParameters
    },
)

export const restoreOpal2BandsteerParameters = createAsyncThunk(
    "restoreOpal2BandsteerParameters",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/bandsteering/parameters/restore-defaults`, {
            method: "POST",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Opal2BandsteerParameters
    },
)

interface BandsteerParametersState {
    parameters: Opal2BandsteerParameters | null
    loading: boolean
    lastError: string | null
    lastSuccess: string | null
}

function createInitialState(): BandsteerParametersState {
    return {
        parameters: null,
        loading: false,
        lastError: null,
        lastSuccess: null,
    }
}

export const bandsteeringSlice = createSlice({
    name: "bandsteering",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getOpal2BandsteerParameters.pending, (state, _) => {
            state.loading = true
            state.lastSuccess = null
            state.lastError = null
        })
        builder.addCase(getOpal2BandsteerParameters.fulfilled, (state, action) => {
            state.parameters = action.payload
            state.loading = false
        })
        builder.addCase(getOpal2BandsteerParameters.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to get bandsteering parameters"
        })

        builder.addCase(updateOpal2BandsteerParameters.pending, (state, _) => {
            state.loading = true
            state.lastError = null
        })
        builder.addCase(updateOpal2BandsteerParameters.fulfilled, (state, action) => {
            state.parameters = action.payload
            state.loading = false
            state.lastSuccess = "Updated bandsteering parameters"
        })
        builder.addCase(updateOpal2BandsteerParameters.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to update bandsteering parameters"
        })

        builder.addCase(restoreOpal2BandsteerParameters.pending, (state, _) => {
            state.loading = true
            state.lastError = null
        })
        builder.addCase(restoreOpal2BandsteerParameters.fulfilled, (state, action) => {
            state.parameters = action.payload
            state.loading = false
            state.lastSuccess = "Restored bandsteering parameters"
        })
        builder.addCase(restoreOpal2BandsteerParameters.rejected, (state, action) => {
            state.loading = false
            state.lastError = "Failed to restore defaults"
        })
    },
})

export const bandsteeringReducer = bandsteeringSlice.reducer

export const selectBandsteering = (state: RootState) => state.bandsteering
