import { API_BASE } from "../config"

class AuthError extends Error {
    constructor(message: string) {
        super(message)
        this.name = "AuthError"
    }
}

const refreshTokens = async () => {
    const refreshToken = localStorage.getItem("refresh")
    if (refreshToken == null) {
        navigateToLogin()
        throw new AuthError("No refresh token is found")
    }

    const rsp = await fetch(`${API_BASE}/auth/refresh`, {
        method: "POST",
        headers: { Authorization: `Bearer ${refreshToken}` },
    })

    if (rsp.status !== 200) {
        localStorage.removeItem("access")
        localStorage.removeItem("refresh")
        navigateToLogin()
        throw new AuthError("Can not refresh tokens")
    }

    const tokens = await rsp.json()
    localStorage.setItem("access", tokens.access)
    localStorage.setItem("refresh", tokens.refresh)
}

const navigateToLogin = () => {
    window.location.href = "#/login"
}

export const tryFetch = async (url: string, opts: any = {}, retryCount: number = 1): Promise<any> => {
    const accessToken = localStorage.getItem("access")

    if (accessToken == null) {
        navigateToLogin()
        throw new AuthError("Can not refresh tokens")
    }

    const optsWithToken = structuredClone(opts)
    optsWithToken.headers = {
        ...optsWithToken.headers,
        Authorization: `Bearer ${accessToken}`,
    }

    let rsp = await fetch(`${API_BASE}${url}`, optsWithToken)

    if (rsp.status === 401) {
        if (retryCount === 0) {
            navigateToLogin()
        }
        try {
            await refreshTokens()
            return await tryFetch(url, opts, retryCount - 1)
        } catch (e) {
            console.error(e)
        }
    }

    return rsp
}
