import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Checkbox, Dimmer, Label, Loader, Pagination, Segment, Table } from "semantic-ui-react"
import { fetchCpesList, selectListedCpes } from "../store/slices/cpesSlice"
import { AppDispatch } from "../store/store"

const tableColumns = ["Serial Number", "Endpoint ID", "Last Seen", "Status"]

const CpesListView = () => {
    const dispatch = useDispatch<AppDispatch>()
    const listedCpesData = useSelector(selectListedCpes)

    const setOnlyActive = (onlyActive: boolean) => {
        dispatch(
            fetchCpesList({
                page: 1,
                onlyActive: onlyActive,
            }),
        )
    }

    useEffect(() => {
        dispatch(
            fetchCpesList({
                page: listedCpesData.activePage,
                onlyActive: listedCpesData.onlyActive,
            }),
        )
    }, [])

    let headerCells = tableColumns.map((col, _idx) => <Table.HeaderCell key={col}>{col}</Table.HeaderCell>)
    let devices = listedCpesData.devices

    let content
    if (devices) {
        content = devices.map((cpe, _idx) => (
            <Table.Row key={cpe.serialNumber}>
                <Table.Cell>
                    <Link to={`/cpes/${cpe.serialNumber}`}>{cpe.serialNumber}</Link>
                </Table.Cell>
                <Table.Cell>{cpe.eid}</Table.Cell>
                <Table.Cell>{new Date(cpe.lastSeen).toLocaleString()}</Table.Cell>
                <Table.Cell>
                    {cpe.active ? (
                        <Label circular color="green">
                            Active
                        </Label>
                    ) : (
                        <Label circular color="grey">
                            Inactive
                        </Label>
                    )}
                </Table.Cell>
            </Table.Row>
        ))
    } else {
        content = <div>No CPE could be shown</div>
    }

    return (
        <>
            <Segment>
                <Dimmer inverted active={listedCpesData.loading}>
                    <Loader active={listedCpesData.loading}>Loading</Loader>
                </Dimmer>
                <Checkbox
                    label="Show only active"
                    onChange={(e, { checked }) => {
                        setOnlyActive(checked || false)
                    }}
                    checked={listedCpesData.onlyActive}
                />
                <Table>
                    <Table.Header>
                        <Table.Row>{headerCells}</Table.Row>
                    </Table.Header>
                    <Table.Body>{content}</Table.Body>
                </Table>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Pagination
                        defaultActivePage={listedCpesData.activePage}
                        totalPages={listedCpesData.totalPages}
                        onPageChange={(e, { activePage }) => {
                            console.log(`changing page ${activePage}`)
                            dispatch(
                                fetchCpesList({
                                    page: Number(activePage),
                                    onlyActive: listedCpesData.onlyActive,
                                }),
                            )
                        }}
                    />
                </div>
            </Segment>
        </>
    )
}

export default CpesListView
