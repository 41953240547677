import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Modal, ModalActions, Table } from "semantic-ui-react"
import { CommandType, SupportedObject, createCommandAction } from "../store/slices/commandSlice"
import { selectObjectBeingAdded, setObjectBeingAdded } from "../store/slices/dataModelEditSlice"
import { AppDispatch } from "../store/store"
import { isParamWritable } from "../utils/utils"

export interface AddObjectModalProps {
    data: SupportedObject | null
    setter: (data: SupportedObject | null) => void
}

export const AddObjectModal = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const currentObject = useSelector(selectObjectBeingAdded)
    const [editState, setEditState] = useState<Record<string, string>>({})

    const closeModal = () => {
        dispatch(setObjectBeingAdded(null))
        setEditState({})
    }

    return (
        <>
            <Modal onClose={closeModal} open={currentObject != null}>
                <Modal.Header>Create new instance of {currentObject?.path}</Modal.Header>
                <Modal.Content>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>Parameter</Table.HeaderCell>
                            <Table.HeaderCell>Value</Table.HeaderCell>
                        </Table.Header>
                        {currentObject?.definition.supportedParams.flatMap((param) =>
                            !isParamWritable(param.access) ? null : (
                                <Table.Row>
                                    <Table.Cell>{param.name}</Table.Cell>
                                    <Table.Cell>
                                        <Form>
                                            <Form.Input
                                                value={editState[param.name] || ""}
                                                onChange={(e) =>
                                                    setEditState({
                                                        ...editState,
                                                        [param.name]: e.target.value,
                                                    })
                                                }
                                            />
                                        </Form>
                                    </Table.Cell>
                                </Table.Row>
                            ),
                        )}
                    </Table>
                </Modal.Content>
                <ModalActions>
                    <Button
                        positive
                        onClick={() => {
                            dispatch(
                                createCommandAction(serialNumber, CommandType.Add, {
                                    createObjects: [
                                        {
                                            path: currentObject!.path,
                                            params: editState,
                                        },
                                    ],
                                }),
                            )
                            closeModal()
                        }}
                    >
                        Create
                    </Button>
                    <Button negative onClick={closeModal}>
                        Cancel
                    </Button>
                </ModalActions>
            </Modal>
        </>
    )
}
