export enum UspNotifyType {
    ValueChange = "ValueChange",
    Event = "Event",
    ObjectCreation = "ObjectCreation",
    ObjectDeletion = "ObjectDeletion",
    OperationComplete = "OperationComplete",
}

export interface UspValueChange {
    type: UspNotifyType.ValueChange
    path: string
    value: string
}

export interface UspEvent {
    type: UspNotifyType.Event
    path: string
    name: string
    params: Record<string, string>
}

export interface UspObjectCreation {
    type: UspNotifyType.ObjectCreation
    path: string
    uniqueKeys: Record<string, string>
}

export interface UspObjectDeletion {
    type: UspNotifyType.ObjectDeletion
    path: string
}

export interface UspOperationError {
    code: number
    message: string
}

export interface UspOperationComplete {
    type: UspNotifyType.OperationComplete
    path: string
    commandName: string
    commandKey: string
    outputArgs: Record<string, string> | null
    error: UspOperationError | null
}

export type UspNotify = UspValueChange | UspEvent | UspObjectCreation | UspObjectDeletion | UspOperationComplete
