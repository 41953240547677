import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"

export type EventType = "Association" | "Disassociation" | "FailedConnection"

export interface ConnectivityEvent {
    type: EventType
    timestamp: string
    bssid?: string
    status?: string
    statusCode?: number
    reason?: string
    reasonCode?: number
}

export interface ClientConnectivityEvents {
    macAddress: string
    eventCount: number
    errorCount: number
    events: ConnectivityEvent[]
}

export interface ClientConnectivityEventsResponse {
    clients: ClientConnectivityEvents[]
}

export const getClientConnectivityEvents = createAsyncThunk(
    "getClientConnectivityEvents",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/client-connectivity-events`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as ClientConnectivityEventsResponse
    },
)

interface ClientConnectivityEventsState {
    clients: Record<string, ClientConnectivityEvents>
    loading: boolean
    lastError: string | null
    lastSuccess: string | null
}

function createInitialState(): ClientConnectivityEventsState {
    return {
        clients: {},
        loading: false,
        lastError: null,
        lastSuccess: null,
    }
}

function compareEvents(a: ConnectivityEvent, b: ConnectivityEvent): number {
    const result = a.timestamp.localeCompare(b.timestamp)
    if (result === 0 && a.bssid && b.bssid) {
        return a.bssid.localeCompare(b.bssid)
    }
    return result
}

export const clientConnectivityEventsSlice = createSlice({
    name: "clientConnectivityEvents",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getClientConnectivityEvents.pending, (state, _) => {
            state.loading = true
            state.lastSuccess = null
            state.lastError = null
        })
        builder.addCase(getClientConnectivityEvents.fulfilled, (state, action) => {
            state.clients = {}
            for (const client of action.payload.clients) {
                state.clients[client.macAddress] = {
                    ...client,
                    events: client.events.slice().sort(compareEvents),
                }
            }
            state.loading = false
        })
        builder.addCase(getClientConnectivityEvents.rejected, (state, action) => {
            state.clients = {}
            state.loading = false
            state.lastError = "Failed to get client connectivity events"
        })
    },
})

export const clientConnectivityEventsReducer = clientConnectivityEventsSlice.reducer

export const selectClientConnectivityEvents = (state: RootState) => state.clientConnectivityEvents
